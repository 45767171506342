if(window.innerWidth > 991.98) {
    $('nav li').hover(
        function() {
            $('ul', this).stop().slideDown(300);
        },
            function() {
            $('ul', this).stop().slideUp(300);
        }
    );
 }

